import type { UserContextType } from "./user-context";

/**
 * Return true if the user has a role that *contains* the passed string
 */
export const hasRole = (user: UserContextType | null | undefined, role: string): boolean => {
  if (!user || !user.me.data) {
    return false;
  }
  const { roles = [] } = user?.me?.data ?? {};
  const lowerRole = role.toLowerCase();

  return roles.find((r) => r.toLowerCase().includes(lowerRole)) !== undefined;
};

export const isUserAlpha = (user?: UserContextType): boolean => {
  return hasRole(user, "alpha");
};

export const isUserAlphaOrBeta = (user?: UserContextType): boolean => {
  return hasRole(user, "alpha") || hasRole(user, "beta");
};

export const isUserGamma = (user?: UserContextType): boolean => {
  return hasRole(user, "gamma");
};
