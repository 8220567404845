import ClassroomDropdown from "@components/breadcrumb-bar/classroom-dropdown";
import { getQueryValue, isLinkSelected } from "@components/primary-navigation/helpers";
import type { LinkType } from "@components/primary-navigation/types";
import StyledLink from "@components/styled-link/styled-link";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@infinitaslearning/pixel-design-system";
import CloseIcon from "@infinitaslearning/pixel-icons/Xmark";
import { getAnalyticsTracker } from "@lib/analytics-tracker";
import { useFeatureFlag } from "@utils/use-feature-flag";
import { useRouter } from "next/router";
import { memo } from "react";

type NavSidebarProps = {
  isOpen: boolean;
  links: LinkType[];
  closeAriaLabel: string;
  onClose: () => void;
};

const NavSidebar = ({ isOpen, links, onClose, closeAriaLabel }: NavSidebarProps) => {
  const theme = useTheme();
  const router = useRouter();
  const showLessonFinder = useFeatureFlag("useLessonFinder", false);

  const handleClick = (link: LinkType) => {
    router.push(link.link);
    const elementId = "menu-sidebar-nav-item";
    const itemId = `${link.title}`;
    const type = "link";
    const value = link.link;
    getAnalyticsTracker()?.elementClicked({
      elementId,
      type,
      module: "menu-sidebar",
      itemId,
      value,
    });
  };

  const handleClose = (ctx: "click-away" | "close-button") => {
    onClose();
    const elementId = "close-sidebar";
    const type = "button";
    getAnalyticsTracker()?.elementClicked({
      elementId,
      type,
      module: "menu-sidebar",
      itemId: elementId,
      value: ctx,
    });
  };

  const renderListItem = (link: LinkType) => {
    const selected = isLinkSelected(link, router);
    return (
      <ListItem key={link.link} data-usetiful={link.usetifulTag} sx={{ padding: 0 }}>
        <ListItemButton selected={selected} color="primary" onClick={() => handleClick(link)}>
          <ListItemText
            primary={
              <StyledLink
                key={link.link}
                data-usetiful={link.usetifulTag}
                href={{
                  pathname: link.link,
                  query: getQueryValue(link, router, showLessonFinder),
                }}
                underline="none"
              >
                <Typography
                  variant={selected ? "body1Bold" : "body1"}
                  color={selected ? theme.pixel.color.primary.key : "unset"}
                >
                  {link.title}
                </Typography>
              </StyledLink>
            }
          />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Drawer anchor="left" open={isOpen} onClose={() => handleClose("click-away")}>
      <IconButton
        aria-label={closeAriaLabel}
        onClick={() => handleClose("close-button")}
        sx={{ alignSelf: "flex-end" }}
      >
        <CloseIcon fontSize="large" />
      </IconButton>
      <Stack
        data-usetiful="sidepanel"
        role="presentation"
        flex={1}
        boxSizing="content-box"
        width={300}
        padding={theme.spacing(0, 2)}
        gap={2}
      >
        <Stack>
          <ClassroomDropdown />
        </Stack>
        <List sx={{ flex: 1, overflow: "auto", padding: 0 }}>
          {links.map((link) => renderListItem(link))}
        </List>
      </Stack>
    </Drawer>
  );
};

export default memo(NavSidebar);
