import { getAnalyticsTracker } from "@lib/analytics-tracker";

export const trackInsiderElementClicked = (
  elementId: string,
  type: string,
  itemId: string,
  value?: string,
) => {
  getAnalyticsTracker()?.elementClicked({
    elementId,
    type,
    module: "insider-program",
    itemId,
    value,
  });
};
